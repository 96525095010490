import clsx from 'clsx';
import React from 'react';

import * as styles from './button.module.css';

type ButtonProps = {
  variant?: 'primary' | 'white';
  isLoading?: boolean;
  className?: string;
  children: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  isLoading = false,
  className,
  children,
  ...props
}) => {
  return (
    <button
      className={clsx(
        styles.button,
        variant === 'white' && styles.white,
        className,
      )}
      {...props}
      disabled={props.disabled || isLoading}
    >
      {isLoading && <span className={styles.loader} data-testid="loader" />}
      {children}
    </button>
  );
};

export default Button;
