import { ContractTransactionResponse, isCallException } from 'ethers';
import React, { useEffect, useRef, useState } from 'react';
import Confetti from 'react-confetti';

import iconError from '@/assets/images/icons/icon-error.svg';
import modalBg from '@/assets/images/raffle/modal-bg.jpg';
import Button from '@/components/button/button';
import Dialog from '@/components/dialog/dialog';
import { useWeb3Context } from '@/context/web3-context';

import { END_DAY } from '../config';
import { switchNetwork } from '../helpers';
import { Ticket } from '../types';
import { claimTicketPrize } from '../web3-raffle';

import * as styles from './congrats-modal.module.css';

type CongratsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  ticket?: Ticket;
  setTx: (tx: ContractTransactionResponse) => void;
};

export default function CongratsModal({
  isOpen,
  onClose,
  ticket,
  setTx,
}: CongratsModalProps) {
  const { account, connect } = useWeb3Context();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const confettiRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (!isOpen) return;

    setHeight((confettiRef.current?.clientHeight ?? 0) + 20);
    setWidth((confettiRef.current?.clientWidth ?? 0) + 40);
  }, [isOpen]);

  const tryToConnect = async () => {
    try {
      setIsLoading(true);
      await connect();
    } catch {
      setError('Please connect your wallet first.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = async () => {
    setError('');

    if (!account) {
      return tryToConnect();
    }

    // if (status === 'differentAccount') {
    //   return setError('Please switch to your linked account.');
    // }

    setIsLoading(true);

    const { success } = await switchNetwork();

    if (!success) {
      return setIsLoading(false);
    }

    try {
      const tx = await claimTicketPrize(ticket!.id);
      setTx(tx);
    } catch (err) {
      if (isCallException(err)) {
        if (err.reason === 'Not enough rounds completed') {
          return setError(
            `You can claim your prize after all rounds end on ${END_DAY} 🎉`,
          );
        }
        return setError(
          err.reason || 'Something went wrong. Please try again.',
        );
      }

      setError('Something went wrong. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (!ticket) {
    return null;
  }

  const text =
    ticket.winner === '10k USDC' ? (
      <>
        You are the Grand winner of Superfan Sneak-A-Groopie.
        <br />
        <strong>10000 USDC</strong> is all yours to claim!
      </>
    ) : (
      <>
        You are one of the lucky Superfans Celebration Event winners. You won{' '}
        <strong>{ticket.winner}</strong>, claim it now.
      </>
    );

  return (
    <Dialog.Container
      ref={confettiRef}
      open={isOpen}
      onClose={onClose}
      className={styles.container}
    >
      <Confetti width={width} height={height} />
      <Dialog.Body>
        <div className={styles.bgContainer}>
          <img className={styles.bgImg} src={modalBg} alt="" />
        </div>
        <div className={styles.wrapper}>
          <img src={ticket.image} alt="ticket" className={styles.ticket} />
          <h3 className={styles.congratsHeading}>Congratulations!!!</h3>
          <p className={styles.congratsText}>{text}</p>
          {!!error && (
            <div className={styles.errorContainer}>
              <img src={iconError} alt="" />
              <p className={styles.error}>{error}</p>
            </div>
          )}
          <Button
            className={styles.button}
            onClick={handleClick}
            isLoading={isLoading}
            variant="white"
          >
            Claim Now
          </Button>
        </div>
      </Dialog.Body>
    </Dialog.Container>
  );
}
