import React from 'react';

import Button from '../button/button';

type SignatureModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSignClick: () => void;
};

export default function SignatureModal({
  isOpen,
  isLoading,
  onClose,
  onSignClick,
}: SignatureModalProps) {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="gatsby-modal confirm-withdraw">
      <div className="gatsby-modal-dialog">
        <div className="gatsby-modal-content">
          <div className="gatsby-modal-body">
            <button className="gatsby-modal-close" onClick={onClose}>
              <span className="material-icons">close</span>
            </button>
            <div className="nowidth-dis">
              <p>
                Sign in to authenticate your Metamask wallet and load your NFTs
                into the game
              </p>
            </div>
          </div>
          <div className="gatsby-modal-footer">
            <div
              className="submit-verification-info text-center mt-4"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button isLoading={isLoading} onClick={onSignClick}>
                Sign
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
