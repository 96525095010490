import * as Sentry from '@sentry/gatsby';
import { ContractTransactionResponse, isCallException } from 'ethers';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Spinner from '@/components/spinner/spinner';
import { useWeb3Context } from '@/context/web3-context';

import { END_DAY } from '../config';
import { switchNetwork } from '../helpers';
import type { Ticket as TTicket } from '../types';
import { claimTicketPrize } from '../web3-raffle';

import * as styles from './ticket.module.css';

export default function Ticket({
  ticket,
  setTx,
}: {
  ticket: TTicket;
  setTx: (tx: ContractTransactionResponse) => void;
}) {
  const { account, connect } = useWeb3Context();
  const [isLoading, setIsLoading] = useState(false);

  const tryToConnect = async () => {
    try {
      setIsLoading(true);
      await connect();
      toast.success(
        'Wallet connected successfully. Click the ticket to claim your prize.',
      );
    } catch {
      toast.error('Please connect your wallet first.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = async () => {
    if (!account) {
      return tryToConnect();
    }

    // if (status === 'differentAccount') {
    //   toast.clearWaitingQueue();
    //   return toast.error('Please switch to your linked account.');
    // }

    setIsLoading(true);

    const { success } = await switchNetwork();

    if (!success) {
      return setIsLoading(false);
    }

    try {
      const tx = await claimTicketPrize(ticket.id);
      setTx(tx);
    } catch (err) {
      console.error(err);
      if (isCallException(err)) {
        if (err.reason === 'Not enough rounds completed') {
          return toast.info(
            `You can claim your prize after all rounds end on ${END_DAY} 🎉`,
          );
        }
        if (err.reason === 'Caller is not the owner of the token') {
          Sentry.captureMessage(
            JSON.stringify({
              err: 'Caller is not the owner of the token',
              account,
            }),
          );
          Sentry.captureException(err);
          return toast.error("You're not the owner of the token.");
        }
        return toast.error(err.reason);
      }

      Sentry.captureException(err);

      toast.error('Something went wrong. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const isWinnerAndNotClaimed = ticket.winner && !ticket.claimed;

  if (isWinnerAndNotClaimed) {
    return (
      <button
        onClick={handleClick}
        disabled={isLoading}
        className={styles.button}
      >
        {isLoading && (
          <div className={styles.buttonSpinner}>
            <Spinner />
          </div>
        )}
        <img
          className={styles.ticketImg}
          src={ticket.image}
          alt={`Ticket ${ticket.id}`}
        />
      </button>
    );
  }

  return (
    <img
      className={styles.ticketImg}
      src={ticket.image}
      alt={`Ticket ${ticket.id}`}
    />
  );
}
