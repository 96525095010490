import clsx from 'clsx';
import React, {
  HTMLAttributes,
  SyntheticEvent,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import iconCloseBold from '@/assets/images/icons/icon-close-bold.svg';

import * as styles from './dialog.module.css';

// I'll refine this later with the issue https://onjoyride.atlassian.net/browse/JI-29477

type ContainerProps = {
  open: boolean;
  locked?: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: HTMLAttributes<HTMLDialogElement>['className'];
};

const Container = forwardRef(function Container(
  { open, locked = false, onClose, className, children }: ContainerProps,
  ref: React.Ref<HTMLDivElement>,
) {
  const modalRef = useRef<HTMLDialogElement>(null);

  const onCancel = useCallback(
    (e: SyntheticEvent<HTMLDialogElement, Event>) => {
      e.preventDefault();
      if (!locked) onClose();
    },
    [locked, onClose],
  );

  const onClick = useCallback(
    ({ target }: SyntheticEvent<HTMLDialogElement, Event>) => {
      const { current: el } = modalRef;
      if (target === el && !locked) onClose();
    },
    [locked, onClose],
  );

  const onAnimEnd = useCallback(() => {
    const { current: el } = modalRef;
    if (!open) {
      el?.close();
      document.body.classList.remove(styles.modalOpen);
    }
  }, [open]);

  useEffect(() => {
    const { current: el } = modalRef;
    if (open) {
      el?.showModal();
      document.body.classList.add(styles.modalOpen);
    }
  }, [open]);

  return (
    <dialog
      ref={modalRef}
      className={clsx(styles.modal, !open && styles.closing, className)}
      onClose={onClose}
      onCancel={onCancel}
      onClick={onClick}
      onAnimationEnd={onAnimEnd}
    >
      <div ref={ref} className={styles.container}>
        <button className={styles.closeButton} onClick={onClose}>
          <img src={iconCloseBold} alt="close" width={40} />
        </button>
        {children}
      </div>
    </dialog>
  );
});

function Header({
  children,
  withBorder = false,
}: {
  children: React.ReactNode;
  withBorder?: boolean;
}) {
  return (
    <header
      className={clsx(styles.header, withBorder && styles.headerWithBorder)}
    >
      <h3>{children}</h3>
    </header>
  );
}

function Body({ children }: { children: React.ReactNode }) {
  return <div className={styles.body}>{children}</div>;
}

const Dialog = { Container, Header, Body };

export default Dialog;
