import { BrowserProvider, JsonRpcProvider } from 'ethers';

import { CONTRACT_ADDRESS, RPC_URL } from './config';
import transformTicketSVG from './transform-ticket-svg';
import { RaffleTicketMetadata, Winner } from './types';
import { RaffleAbi, RaffleAbi__factory } from './types/ethers-contracts';

let readOnlyContract: RaffleAbi;

function getReadOnlyContract() {
  if (!readOnlyContract) {
    readOnlyContract = RaffleAbi__factory.connect(
      CONTRACT_ADDRESS,
      new JsonRpcProvider(RPC_URL),
    );
  }

  return readOnlyContract;
}

export async function getTicketURI(tokenId: string) {
  const dataURL = await getReadOnlyContract().tokenURI(tokenId);
  const dataType = 'data:application/json;base64,';

  if (!dataURL.startsWith(dataType)) {
    console.error('Invalid data URL.');
    return;
  }

  const json = atob(dataURL.slice(dataType.length));
  const data: RaffleTicketMetadata = JSON.parse(json);

  const winner = data.attributes.find((attr) => attr?.trait_type === 'Winner')
    ?.value as Winner | undefined;

  const claimed =
    data.attributes.find((attr) => attr?.trait_type === 'Claimed')?.value ===
    'TRUE'
      ? true
      : false;

  const eliminated =
    data.attributes
      .find((attr) => attr?.trait_type === 'Status')
      ?.value.startsWith('Eliminated') ?? false;

  const transformedImage = transformTicketSVG({
    base64string: data.image_data,
    winner,
    claimed,
    eliminated,
  });

  const transformedData = {
    id: tokenId,
    image: transformedImage,
    winner,
    claimed,
  };

  return transformedData;
}

export async function getRoundResults(): Promise<
  [number, number, number, number, number, number]
> {
  try {
    const bigIntArr = await getReadOnlyContract().winningSeat();

    const intArr: number[] = [];

    bigIntArr.forEach((item: bigint) => {
      intArr.push(parseInt(item.toString()));
    });

    return intArr as [number, number, number, number, number, number];
  } catch (err) {
    console.error(err);
    return [0, 0, 0, 0, 0, 0];
  }
}

export async function claimTicketPrize(tokenId: string) {
  const provider = new BrowserProvider(window.ethereum!);
  const signer = await provider.getSigner();

  const contract = RaffleAbi__factory.connect(CONTRACT_ADDRESS, signer);
  const tx = await contract.claimPrizes(tokenId);

  return tx;
}
