import React from 'react';

import { Web3Provider } from '@/context/web3-context';

import * as styles from './dashboard-layout.module.css';
import LoginHeader from './login-header';
import Navbar from './navbar';

export interface IDashboardLayout {
  hasBorder?: boolean;
  className?: string;
  children: React.ReactNode;
}

const DashboardLayout: React.FC<IDashboardLayout> = ({
  hasBorder = false,
  className = 'dashboard',
  children,
}) => {
  return (
    <Web3Provider>
      <div className={`${styles.wrapper} ${className}`}>
        <LoginHeader />
        <Navbar />
        {hasBorder && <div className="nav-border-top"></div>}
        <main className={styles.container}>{children}</main>
      </div>
    </Web3Provider>
  );
};

export default DashboardLayout;

export const withDashboardLayout = (Component: React.FC<any>) => {
  return () => (
    <DashboardLayout>
      <Component />
    </DashboardLayout>
  );
};
