import { Winner } from './types';

let nodesForStatus: SVGGElement;

function getNodesForStatus() {
  if (nodesForStatus) return nodesForStatus;

  const svgString = `
    <g fill="#fff" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0" class="e">
        <rect fill="#000000AA" width="2000" height="2000" />
        <g>
          <text font-size="100" transform="matrix(0.9948 -0.1015 0.1015 0.9948 148 555)">ELIMINATED</text>
          <path class="y2"
            d="M896.16,524.15l-770.04,78.57c-6.57,0.67-12.49-4.15-13.16-10.72L98.6,451.29c-0.67-6.57,4.15-12.49,10.72-13.16l770.04-78.57c6.57-0.67,12.49,4.15,13.16,10.72l14.36,140.71C907.55,517.56,902.72,523.48,896.16,524.15z" />
        </g>
      </g>
      <g opacity="0" class="c">
        <rect fill="#0566C688" width="2000" height="2000"/>
        <path class="y2" d="M791.87,488.44L184.4,550.43c-6.57,0.67-12.49-4.15-13.16-10.72L156.89,399c-0.67-6.57,4.15-12.49,10.72-13.16l607.47-61.99c6.57-0.67,12.49,4.15,13.16,10.72l14.36,140.71C803.26,481.85,798.44,487.77,791.87,488.44z"/>
        <g transform="matrix(0.9948 -0.1015 0.1015 0.9948 790 475)">
          <text x="-618" y="-65" font-size="100px" text-decoration="underline">CLAIMED!</text>
          <text opacity="0" class="y3 p1">50 USDC</text>
          <text opacity="0" class="y3 p2">300 USDC</text>
          <text opacity="0" class="y3 p3">10K USDC</text>
        </g>
      </g>
      <g opacity="0" class="w">
        <rect fill="#137E2A99" width="2000" height="2000"/>
        <g>
          <path class="y2" d="M796.87,487.44L189.4,549.43c-6.57,0.67-12.49-4.15-13.16-10.72L161.89,398c-0.67-6.57,4.15-12.49,10.72-13.16l607.47-61.99c6.57-0.67,12.49,4.15,13.16,10.72l14.36,140.71C808.26,480.85,803.44,486.77,796.87,487.44z"/>
          <g transform="matrix(0.9948 -0.1015 0.1015 0.9948 790 475)">
            <text x="-612" y="-65" font-size="100px" text-decoration="underline">WINNER!!!</text>
            <text opacity="0" class="y3 p1">50 USDC</text>
            <text opacity="0" class="y3 p2">300 USDC</text>
            <text opacity="0" class="y3 p3">10K USDC</text>
          </g>
        </g>
      </g>
    </g>
  `;

  const parser = new DOMParser();
  const document = parser.parseFromString(svgString, 'image/svg+xml');
  nodesForStatus = document.querySelector('g')!;

  return nodesForStatus;
}

function getStylesForStatus(
  winner: Winner | undefined,
  claimed: boolean,
  eliminated: boolean,
) {
  let styles = '';

  if (winner) {
    const winnerStyles = {
      '50 USDC': '.p1{opacity:1.0}',
      '300 USDC': '.p2{opacity:1.0}',
      '10k USDC': '.p3{opacity:1.0}',
    };

    styles = `${winnerStyles[winner]} ${
      claimed ? '.c{opacity:1.0}' : '.w{opacity:1.0}'
    }`;
  } else if (eliminated) {
    styles = '.e{opacity:1.0}';
  }

  return styles;
}

export default function transformTicketSVG({
  base64string,
  winner,
  claimed,
  eliminated,
}: {
  base64string: string;
  claimed: boolean;
  winner?: Winner;
  eliminated: boolean;
}) {
  const dataType = 'data:image/svg+xml;base64,';

  if (!base64string.startsWith(dataType)) {
    throw new Error('Invalid data URL');
  }

  const str = atob(base64string.slice(dataType.length));
  const parser = new DOMParser();
  const document = parser.parseFromString(str, 'image/svg+xml');

  const svg = document.querySelector('svg')!;
  svg.setAttribute('width', '1100px');
  svg.setAttribute('height', '500px');
  svg.setAttribute('viewBox', '40 270 1100 500');

  const baseImage = document.querySelector('#Base_Image');
  new Array(5).fill(null).forEach(() => {
    baseImage?.removeChild(baseImage?.children[0]);
  });

  const styleEls = document.querySelectorAll('style');
  const lastStyleEl = styleEls[styleEls.length - 1];

  lastStyleEl.innerHTML += getStylesForStatus(winner, claimed, eliminated);

  document.querySelector('g')!.appendChild(getNodesForStatus());

  const svgAsXML = new XMLSerializer().serializeToString(svg);
  const svgData = dataType + btoa(svgAsXML);

  return svgData;
}

export function removeWinnerStylesFromSVG(base64string: string) {
  const dataType = 'data:image/svg+xml;base64,';

  if (!base64string.startsWith(dataType)) {
    throw new Error('Invalid data URL');
  }

  const str = atob(base64string.slice(dataType.length));
  const parser = new DOMParser();
  const document = parser.parseFromString(str, 'image/svg+xml');
  const svg = document.querySelector('svg')!;

  const styleEls = document.querySelectorAll('style');
  const lastStyleEl = styleEls[styleEls.length - 1];

  lastStyleEl.innerHTML +=
    '.p1{opacity:0} .p2{opacity:0} .p3{opacity:0} .c{opacity:0} .w{opacity:0}';

  const svgAsXML = new XMLSerializer().serializeToString(svg);
  const svgData = dataType + btoa(svgAsXML);

  return svgData;
}
