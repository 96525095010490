import React, { Fragment, useEffect, useState } from 'react';

import * as styles from './countdown.module.css';

export default function Countdown({ timestamp }: { timestamp: number }) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();

      const distance = timestamp - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance <= 0) {
        clearInterval(interval);
        setDays(0);
        setHours(0);
        setMinutes(0);
        setSeconds(0);
      } else {
        setDays(days);
        setHours(hours);
        setMinutes(minutes);
        setSeconds(seconds);
      }

      setIsLoading(false);
    }, 1000);

    return () => clearInterval(interval);
  }, [timestamp]);

  const rows = [
    { label: 'Days', value: days },
    { label: 'Hours', value: hours },
    { label: 'Minutes', value: minutes },
    { label: 'Seconds', value: seconds },
  ];

  return (
    <div
      className={styles.container}
      style={{
        visibility: isLoading ? 'hidden' : 'visible',
      }}
    >
      {rows.map(({ label, value }, i) => (
        <Fragment key={label}>
          <div className={styles.row}>
            <div className={styles.value}>{value}</div>
            <div className={styles.label}>{label}</div>
          </div>
          {i < rows.length - 1 && <div className={styles.value}>:</div>}
        </Fragment>
      ))}
    </div>
  );
}
