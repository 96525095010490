import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import useLocalStorageState from 'use-local-storage-state';

import { useRewardPoints } from '@/api/api-hooks';
import ApiUser from '@/api/user';
import rallyIcon from '@/assets/images/rally-token.svg';
import { BiTrigger } from '@/bi/bi-types';
import CONSTANTS from '@/common/constants';
import { useAuth } from '@/context/auth-context';
import { useWeb3Context } from '@/context/web3-context';

import * as styles from './metamask-button-header.module.css';

type MetamaskButtonHeaderProps = {
  status: string;
  biTrigger: BiTrigger;
};

export default function MetamaskButtonHeader({
  status,
  biTrigger,
}: MetamaskButtonHeaderProps) {
  const { user } = useAuth();
  const { account } = useWeb3Context();
  const { rewardCurrency, rewardPoints } = useRewardPoints(
    user?.appUser?.user?.id,
  );
  const [rewardedIds, setRewardedIs] = useLocalStorageState<string[]>(
    CONSTANTS.LOCAL_STORAGE_KEYS.MM_CONNECTION_REWARD_USER_IDS,
    {
      defaultValue: [],
    },
  );

  useEffect(() => {
    if (!account || !user) return;

    const checkReward = async () => {
      if (!rewardedIds.includes(user.appUser.user.id) && account) {
        const newRewardedIds = [...rewardedIds, user.appUser.user.id];
        try {
          const { data } = await ApiUser.getRewardOnMmConnection(
            user.appUser.user.id,
          );

          if (data === false) return;

          setRewardedIs(newRewardedIds);
          toast.success('Reward claimed! 🏆');
        } catch (e) {
          if (e?.data?.errorCode === 'Invalid_Metamask_connection_reward') {
            setRewardedIs(newRewardedIds);
          }
          toast.error(e?.data?.detail ?? 'Something went wrong!');
        }
      }
    };

    checkReward();
  }, [account, user, rewardedIds, setRewardedIs]);

  if (!user || biTrigger === 'deposit') {
    return null;
  }

  if (
    rewardCurrency &&
    rewardPoints &&
    status === 'connect' &&
    !rewardedIds.includes(user.appUser.user.id)
  ) {
    return (
      <p className={styles.buttonHeader}>
        Earn <img src={rallyIcon} alt="" height={20} />{' '}
        <strong>
          {rewardPoints} ${rewardCurrency}
        </strong>{' '}
        by connecting Wallet
      </p>
    );
  }

  if (status === 'unsigned') {
    return (
      <p className={styles.buttonHeader}>
        {['wallet', 'withdraw'].includes(biTrigger)
          ? 'Sign your wallet'
          : 'Sign your wallet to load your NFTs into the game'}
      </p>
    );
  }

  return null;
}
