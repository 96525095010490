import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import loader from '@/assets/images/raffle/loader.svg';

import { DRAW_ROUNDS } from '../config';
import { getRoundResults } from '../web3-raffle';

import * as styles from './schedule.module.css';

type RoundWithResult = (typeof DRAW_ROUNDS)[0] & { result?: string };

type ItemProps = {
  item: RoundWithResult;
  isLast?: boolean;
};

function Item({ item, isLast = false }: ItemProps) {
  return (
    <div className={styles.item}>
      <div className={styles.top}>
        <h5>{item.heading}</h5>
        <h4>{item.date}</h4>
      </div>
      <div className={styles.column}>
        <p className={styles.bold}>
          QUALIFIED
          <br /> {item.attribute}
        </p>
      </div>
      <div className={styles.column}>
        <p className={clsx(styles.bold, item.result && styles.determined)}>
          {item.amount}
        </p>
      </div>
      <div className={styles.status}>
        {item?.result ? (
          isLast ? (
            <>
              <p className={styles.congrats}>Congratulations!</p>
              <p className={styles.bold}>
                {item.attribute} {item.result}
              </p>
              <p className={styles.qualified}>is the winner of the 10k USDC</p>
            </>
          ) : (
            <>
              <p className={styles.congrats}>Congratulations!</p>
              <p className={styles.bold}>
                {item.attribute} {item.result}
              </p>
              <p className={styles.qualified}>
                is qualified for the next round
              </p>
            </>
          )
        ) : (
          <img src={loader} alt="loader" width={48} />
        )}
      </div>
    </div>
  );
}

export default function Schedule() {
  const [roundsWithResults, setRoundsWithResults] = useState<RoundWithResult[]>(
    [],
  );
  const currentRound =
    DRAW_ROUNDS.find((date) => Date.now() < date.dateMs)?.round ?? -1;

  useEffect(() => {
    async function mapResults() {
      const winners = await getRoundResults();

      const arr: RoundWithResult[] = [];

      DRAW_ROUNDS.forEach((round) => {
        return arr.push({
          ...round,
          result: round.values[winners[round.round - 1] - 1],
        });
      });

      setRoundsWithResults(arr);
    }

    mapResults();
  }, [currentRound]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {roundsWithResults.map((item, i) => (
          <Item
            key={item.heading}
            item={item}
            isLast={i === roundsWithResults.length - 1}
          />
        ))}
      </div>
    </div>
  );
}
