import { toast } from 'react-toastify';

import { BLOCK_EXPLORER_URL, CHAIN_DATA } from './config';
import { CongratsModalData } from './types';

const addNetwork = async () => {
  try {
    await window.ethereum!.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainName: CHAIN_DATA.name,
          chainId: CHAIN_DATA.chainId,
          nativeCurrency: CHAIN_DATA.nativeCurrency,
          rpcUrls: CHAIN_DATA.rpcUrls,
        },
      ],
    });

    return { success: true };
  } catch (err: any) {
    if (err?.code === 4001) {
      toast.error(`Please add the ${CHAIN_DATA.name} network to your wallet.`);
    } else {
      toast.error('Something went wrong. Please try again.');
    }
    return { success: false };
  }
};

export const switchNetwork: () => Promise<{ success: boolean }> = async () => {
  try {
    await window.ethereum!.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: CHAIN_DATA.chainId }],
    });

    return { success: true };
  } catch (err: any) {
    if (err?.code === 4001) {
      // 4001: User rejected request
      toast.error(
        `Please switch your wallet to the ${CHAIN_DATA.name} network.`,
      );
    } else if (err?.code === 4902) {
      // 4902: Unknown network
      const { success } = await addNetwork();

      if (success) {
        return switchNetwork();
      }
    } else {
      toast.error('Something went wrong. Please try again.');
    }

    return { success: false };
  }
};

export const generateBlockExplorerUrl = (txHash?: string) => {
  return `${BLOCK_EXPLORER_URL}/tx/${txHash}`;
};

const ONE_DAY = 1000 * 60 * 60 * 24;
const STORAGE_KEY = 'CONGRATS_MODAL';

export function shouldShowCongratsModal() {
  try {
    const { showCount, firstShowAt } = JSON.parse(
      localStorage.getItem(STORAGE_KEY) ??
        '{ "showCount": 0, "firstShowAt": 0 }',
    ) as CongratsModalData;

    const clonedData = { showCount, firstShowAt };
    const setData = (data: CongratsModalData) =>
      localStorage.setItem(STORAGE_KEY, JSON.stringify(data));

    if (showCount === 0) {
      clonedData.showCount++;
      clonedData.firstShowAt = Date.now();
      setData(clonedData);
      return true;
    }

    if (showCount === 1) {
      clonedData.showCount++;
      setData(clonedData);
      return true;
    }

    const now = Date.now();
    const diff = now - firstShowAt;

    if (diff > ONE_DAY) {
      clonedData.showCount = 1;
      clonedData.firstShowAt = now;
      setData(clonedData);
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.error(e);
    return true;
  }
}
