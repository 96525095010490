import { Link, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';

// import { useWalletSummary } from '@/api/api-hooks';
import ApiUser from '@/api/user';
import '@/assets/css/style.css';
import iconClose from '@/assets/images/icons/icon-close.svg';
import iconMenu from '@/assets/images/icons/icon-menu.svg';
import iconWallet from '@/assets/images/icons/icon-wallet.svg';
// import rallyImg from '@/assets/images/rally-token.svg';
import { ECtaNames, EWebsiteTabNames } from '@/bi/bi-constants';
import { commonBiEvents } from '@/bi/events/common-bi-events';
import utils, { isBrowser } from '@/common/utils';
import { useAuth } from '@/context/auth-context';
import { useWeb3Context } from '@/context/web3-context';

// import TokenIcon from '../token-icon/token-icon';

import MetamaskButton from '../metamask-button/metamask-button';

import * as styles from './login-header.module.css';
import { register } from '@/common/web3-access-pass-helpers';

let statusTimer: any;

const DefaultHeader: React.FC = () => {
  const { user } = useAuth();
  const { status } = useWeb3Context();
  const [showMMButton, setShowMMButton] = useState(false);

  // const { jrWalletBalance } = useWalletSummary();

  const [showMenu, setShowMenu] = useState(false);

  const logout = async () => {
    commonBiEvents.userClicksOnSomeCta(
      EWebsiteTabNames.ACCOUNT_BALANCE_WALLET,
      ECtaNames.LOGOUT,
    );
    try {
      await ApiUser.logout(user!.appUser.user.id);
    } catch (e) {
      console.error('logout failed\n', e);
    } finally {
      utils.logoutUser();

      // navigate("/login");
      // using following method instead of navigate() to reset auth state which
      // is causing problem during auto MM connect
      if (isBrowser()) {
        window.location.href = `/login/${window.location.search}`;
      }
    }
  };

  const login = () => {
    navigate('/login');
  };

  const getDelayedStatus = () => {
    clearTimeout(statusTimer);
    statusTimer = setTimeout(() => {
      let flag = false;
      if (status !== 'signed') {
        flag = true;
      }
      setShowMMButton(flag);
    }, 500);
  };

  useEffect(() => {
    getDelayedStatus();
    // eslint-disable-next-line
  }, [status]);

  return (
    <header className={styles.header}>
      <>&nbsp;</>
      <button
        className={styles.toggleButton}
        onClick={() => setShowMenu((prev) => !prev)}
      >
        {showMenu ? (
          <img src={iconClose} alt="close menu" className={styles.menuIcon} />
        ) : (
          <img src={iconMenu} alt="open menu" className={styles.menuIcon} />
        )}
      </button>
      <nav className={`${styles.menu} ${showMenu ? styles.navbarOpen : ''}`}>
        <ul className="top-nav-links">
          {/* <li>
            <a className={styles.balance}>
              <TokenIcon iconWidth={27} tokenImage={rallyImg} />
              <span>{jrWalletBalance}</span>
            </a>
          </li> */}
          {user &&
            (!showMMButton ? (
              <>
                {/* <li>
                  <Link to="/wallet">
                    <img className={styles.icon} src={iconWallet} alt="Wallet" />
                    <span>Wallet</span>
                  </Link>
                </li> */}
              </>
            ) : (
              <MetamaskButton
                className="white-button no-scale header-metamask"
                hideHeader={true}
                showInfoIcon={false}
                noScale={true}
                biTrigger="racket-rampage"
              />
            ))}
          <li>
            {user && (
              <button className="pink-btn" onClick={logout}>
                Logout
              </button>
            )}
            {!user && (
              <button className="pink-btn" onClick={login}>
                Login
              </button>
            )}
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default DefaultHeader;
