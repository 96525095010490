// extracted by mini-css-extract-plugin
export var bgContainer = "congrats-modal-module--bgContainer--15fc4";
export var bgImg = "congrats-modal-module--bgImg--bd600";
export var button = "congrats-modal-module--button--1c9db";
export var congratsHeading = "congrats-modal-module--congratsHeading--19eb2";
export var congratsText = "congrats-modal-module--congratsText--262df";
export var container = "congrats-modal-module--container--17917";
export var error = "congrats-modal-module--error--1c6d4";
export var errorContainer = "congrats-modal-module--errorContainer--79101";
export var ticket = "congrats-modal-module--ticket--f311e";
export var wrapper = "congrats-modal-module--wrapper--ccd0d";