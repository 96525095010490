// extracted by mini-css-extract-plugin
export var body = "dialog-module--body--410f8";
export var closeButton = "dialog-module--closeButton--4d8cc";
export var closing = "dialog-module--closing--2d5ee";
export var container = "dialog-module--container--588a5";
export var header = "dialog-module--header--0c5cb";
export var headerWithBorder = "dialog-module--headerWithBorder--301d7";
export var hide = "dialog-module--hide--86ac5";
export var modal = "dialog-module--modal--49440";
export var modalOpen = "dialog-module--modalOpen--eb086";
export var show = "dialog-module--show--17c9b";