// extracted by mini-css-extract-plugin
export var bold = "schedule-module--bold--dafbd";
export var column = "schedule-module--column--68653";
export var congrats = "schedule-module--congrats--300fa";
export var container = "schedule-module--container--636f6";
export var determined = "schedule-module--determined--2b949";
export var item = "schedule-module--item--3da98";
export var qualified = "schedule-module--qualified--92f29";
export var status = "schedule-module--status--86d69";
export var top = "schedule-module--top--8542f";
export var wrapper = "schedule-module--wrapper--7af4b";