import clsx from 'clsx';
import React from 'react';

import { useRaffleTicketIds } from '@/api/api-hooks';
import iconChevronDown from '@/assets/images/icons/icon-chevron-down.svg';
import mascot from '@/assets/images/raffle/mascot.png';
import separator from '@/assets/images/raffle/separator.png';
import { withDashboardLayout } from '@/components/layout/dashboard-layout';

import Countdown from './components/countdown';
import Schedule from './components/schedule';
import TicketList from './components/ticket-list';
import { MAGIC_EDEN, ROUNDS } from './config';
import * as styles from './raffle-page.module.css';

const TICKET_LIST_ID = 'ticket-list';

function RafflePage() {
  const { tokenIds } = useRaffleTicketIds();

  const currentRound =
    ROUNDS.find((date) => Date.now() < date.dateMs)?.round ?? -1;
  const isMagicEdenEnded = Date.now() > MAGIC_EDEN.dateMs;
  const isDrawingEnded = currentRound === -1;

  return (
    <>
      <section className={styles.container}>
        <div className={styles.boxContainer}>
          <div className={styles.box}>
            <h1>
              Superfan
              <br /> Sneak-A-Groopie
            </h1>
            <section className={styles.desc}>
              <p>
                The Groopies are running a raffle with 16K USDC + FREE Groupies
                NFT mint spots at stake.
              </p>
              <p>
                60 winners to be chosen. One Grand Prize Winner will win a{' '}
                <strong>10K USDC</strong> prize all to themselves!
              </p>
            </section>
            <h3>
              Winners will be drawn in 6 elimination rounds starting on{' '}
              {ROUNDS[1].dateString}
            </h3>

            <img src={separator} className={styles.separator} alt="" />

            <section className={styles.mintDateSection}>
              <div
                className={clsx(
                  styles.mintDateContainer,
                  currentRound !== 0 && styles.passiveAirdropContainer,
                  currentRound === 0 && styles.activeDrawContainer,
                )}
              >
                <h4>
                  <s>
                    Premint Airdrop
                    <br /> Live Now
                  </s>
                </h4>
                <div className={styles.line} />
                <p className={styles.date}>
                  ALLOCATION
                  <br /> FULL
                </p>
              </div>

              <div
                className={clsx(
                  styles.mintDateContainer,
                  isMagicEdenEnded && styles.passiveAirdropContainer,
                  currentRound === 1 &&
                    !isMagicEdenEnded &&
                    styles.activeDrawContainer,
                )}
              >
                <h4>
                  Magic Eden
                  <br /> Mint
                </h4>
                <div className={styles.line} />
                <p className={styles.date}>{MAGIC_EDEN.endDateString}</p>
                <p className={styles.time}>4pm UTC | 12pm EST | 9am PST</p>
              </div>

              <div
                className={clsx(
                  styles.mintDateContainer,
                  currentRound && styles.activeDrawContainer,
                  isDrawingEnded && styles.passiveAirdropContainer,
                )}
              >
                <h4>
                  {currentRound > 1 ? (
                    <>
                      Raffle Draw
                      <br /> Round {currentRound}
                    </>
                  ) : (
                    <>
                      Raffle <br /> Draw
                    </>
                  )}
                </h4>
                <div className={styles.line} />
                <p className={styles.date}>
                  {currentRound > 1
                    ? ROUNDS[currentRound].dateString
                    : ROUNDS[1].dateString}
                </p>
                <p className={styles.time}>
                  5:30pm UTC | 1:30pm EST | 10:30am PST
                </p>
              </div>
            </section>

            <section className={styles.currentPhase}>
              <p className={clsx(currentRound === 0 && styles.activePhase)}>
                30K TICKETS
                <br /> TO CLAIM
              </p>
              <p className={clsx(!isDrawingEnded && styles.activePhase)}>
                RAFFLE DRAW{' '}
                {currentRound > 1 && (
                  <>
                    <br />
                    RD {currentRound}
                  </>
                )}
              </p>
            </section>

            <Countdown
              timestamp={currentRound > -1 ? ROUNDS[currentRound].dateMs : 0}
            />

            <img
              src={separator}
              className={styles.separator}
              alt=""
              style={{ transform: 'rotate(180deg)' }}
            />

            <section className={styles.premint}>
              {isDrawingEnded ? (
                <h3>The Superfan Sneak-A-Groupie has ended</h3>
              ) : currentRound === 0 ? (
                <>
                  <h3 style={{ marginBottom: 0 }}>
                    Premint.xyz allocation is now full
                  </h3>
                  <h3>Get ready for the mint on {MAGIC_EDEN.endDateString}</h3>
                </>
              ) : (
                <h3>
                  Round {currentRound} of Raffle
                  <br /> Draw Coming Soon
                </h3>
              )}
              {isDrawingEnded ? (
                <p>Join Discord to know more about our upcoming events</p>
              ) : currentRound === 0 ? (
                <p>Only 30K tickets available</p>
              ) : (
                <p>
                  You are air dropped with {tokenIds?.length ?? 0} Raffle
                  tickets. <br /> Visit Magic Eden to buy more tickets.
                </p>
              )}
              {isDrawingEnded ? (
                <a
                  href="https://discord.com/invite/superchamps"
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong>Discord</strong>
                </a>
              ) : (
                <>
                  <a
                    href="https://magiceden.io/collections/polygon/superfans_celebration"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>Magic Eden</strong>
                  </a>

                  <p style={{ paddingTop: 20 }}>
                    Premint.xyz allocation is now full
                  </p>
                  <a
                    href="https://www.premint.xyz/groopie-raffle-royale-p3/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Premint.xyz
                  </a>
                </>
              )}
            </section>
          </div>
        </div>
        <div className={styles.mascotContainer}>
          <div className={styles.buttonContainer}>
            <button
              className={styles.downButton}
              onClick={() => {
                document.querySelector(`#${TICKET_LIST_ID}`)?.scrollIntoView({
                  behavior: 'smooth',
                });
              }}
            >
              <span>Collection</span>
              <img src={iconChevronDown} height={36} alt="go to tickets" />
            </button>
          </div>
          <img src={mascot} className={styles.mascot} alt="" />
        </div>
      </section>

      <TicketList id={TICKET_LIST_ID} />

      <section className={styles.scheduleContainer}>
        <h2 className={styles.sectionHeading}>
          30,000 NFT Raffle Ticket Schedule
        </h2>
        <Schedule />
      </section>
    </>
  );
}

export default withDashboardLayout(RafflePage);
