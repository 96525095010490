import { JR_ENV } from '@/common/constants/env';

const isProdOrProdtestnet = JR_ENV === 'production' || JR_ENV === 'prodtestnet';
const airdropDate = 1686067200000; // 6/6/23 16 UTC
const drawDate = 1686331800000; // 6/9/23 17:30 UTC
const oneDay = 86400000;

export const END_DAY = '15th June';

export const MAGIC_EDEN = {
  dateString: '5/19/23',
  endDateString: '6/7/23',
  dateMs: 1686153600000,
  round: 0,
  heading: '',
  date: '',
  attribute: '',
  amount: '',
  values: [],
};

export const ROUNDS = [
  {
    dateString: '6/6/23',
    dateMs: airdropDate,
    round: 0,
    heading: '',
    date: '',
    attribute: '',
    amount: '',
    values: [],
  },
  {
    dateString: '6/9/23',
    dateMs: drawDate,
    round: 1,
    heading: 'Round 1',
    date: 'JUNE 9',
    attribute: 'TIER',
    amount: '7,500',
    values: ['I', 'II', 'III', 'IV'],
  },
  {
    dateString: '6/10/23',
    dateMs: drawDate + oneDay,
    round: 2,
    heading: 'Round 2',
    date: 'JUNE 10',
    attribute: 'ZONE',
    amount: '1500',
    values: ['Red', 'Blue', 'Yellow', 'Green', 'Orange'],
  },
  {
    dateString: '6/11/23',
    dateMs: drawDate + oneDay * 2,
    round: 3,
    heading: 'Round 3',
    date: 'JUNE 11',
    attribute: 'SECTION',
    amount: '300',
    values: ['A', 'B', 'C', 'D', 'E'],
  },
  {
    dateString: '6/12/23',
    dateMs: drawDate + oneDay * 3,
    round: 4,
    heading: 'Round 4',
    date: 'JUNE 12',
    attribute: 'BOX',
    amount: '60',
    values: ['1', '2', '3', '4', '5'],
  },
  {
    dateString: '6/13/23',
    dateMs: drawDate + oneDay * 4,
    round: 5,
    heading: 'Round 5',
    date: 'JUNE 13',
    attribute: 'ROW',
    amount: '12',
    values: ['1', '2', '3', '4', '5'],
  },
  {
    dateString: '6/14/23',
    dateMs: drawDate + oneDay * 5,
    round: 6,
    heading: 'Round 6',
    date: 'JUNE 14',
    attribute: 'SEAT',
    amount: '1',
    values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
  },
];

export const DRAW_ROUNDS = [...ROUNDS].slice(1);

export const CONTRACT_ADDRESS = isProdOrProdtestnet
  ? '0x6EbC6c5D8EA78DE3F3722eE4C1B7EdD28C809aBD'
  : '0x850536c825864c5eeb8c29b09ac0443f1582d9b9';

export const RPC_URL = isProdOrProdtestnet
  ? 'https://polygon.llamarpc.com'
  : 'https://polygon-mumbai-bor.publicnode.com';

const POLYGON_DATA = {
  name: 'Polygon Mainnet',
  chainId: '0x89',
  nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
  rpcUrls: ['https://polygon.llamarpc.com'],
};

const MUMBAI_DATA = {
  name: 'Mumbai Testnet',
  chainId: '0x13881',
  nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
  rpcUrls: ['https://polygon-mumbai-bor.publicnode.com'],
};

export const CHAIN_DATA = isProdOrProdtestnet ? POLYGON_DATA : MUMBAI_DATA;

export const BLOCK_EXPLORER_URL = isProdOrProdtestnet
  ? 'https://polygonscan.com'
  : 'https://mumbai.polygonscan.com';

export const TEST_TOKEN_IDS = [
  '8901',
  '8902',
  '8903',
  '11664',
  '11665',
  '11666',
  '11667',
  '11668',
  '11669',
  '11670',
  '11662',
  '11661',
  '11660',
  '11659',
  '11658',
  '11657',
  '11656',
  '11655',
  '11654',
  '11653',
  '11663',
];
