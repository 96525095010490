/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, ContractRunner } from 'ethers';
import type { RaffleAbi, RaffleAbiInterface } from '../raffle-abi';

const _abi = [
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'tokenURI',
    outputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'winningSeat',
    outputs: [
      {
        internalType: 'uint8',
        name: 'tier',
        type: 'uint8',
      },
      {
        internalType: 'uint8',
        name: 'zone',
        type: 'uint8',
      },
      {
        internalType: 'uint8',
        name: 'section',
        type: 'uint8',
      },
      {
        internalType: 'uint8',
        name: 'box',
        type: 'uint8',
      },
      {
        internalType: 'uint8',
        name: 'row',
        type: 'uint8',
      },
      {
        internalType: 'uint8',
        name: 'seat',
        type: 'uint8',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'tokenID',
        type: 'uint256',
      },
    ],
    name: 'claimPrizes',
    outputs: [
      {
        internalType: 'uint256',
        name: 'totalPrize',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'prizeTier',
        type: 'uint256',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const;

export class RaffleAbi__factory {
  static readonly abi = _abi;
  static createInterface(): RaffleAbiInterface {
    return new Interface(_abi) as RaffleAbiInterface;
  }
  static connect(address: string, runner?: ContractRunner | null): RaffleAbi {
    return new Contract(address, _abi, runner) as unknown as RaffleAbi;
  }
}
