import type { ContractTransactionResponse } from 'ethers';
import React, { useEffect, useState } from 'react';

import utils from '@/common/utils';
import Button from '@/components/button/button';
import CopyButton from '@/components/copy-button/copy-button';
import Dialog from '@/components/dialog/dialog';

import { generateBlockExplorerUrl } from '../helpers';

import * as styles from './transaction-modal.module.css';

type TransactionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  tx: ContractTransactionResponse | null;
};

export default function TransactionModal({
  isOpen,
  onClose,
  tx,
}: TransactionModalProps) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!tx) return;

    tx.wait().then(() => setIsLoading(false));
  }, [tx]);

  const title = isLoading ? 'Confirming Transaction' : 'Transaction Confirmed';
  const body = isLoading
    ? 'Your transaction is being validated on the blockchain. Please wait for your confirmation. Transaction status can be checked through the link provided below.'
    : 'Your transaction is validated on the blockchain. Confirm status through the link provided below.';
  const buttonText = isLoading ? '' : 'Okay';

  return (
    <Dialog.Container open={isOpen} onClose={onClose}>
      <Dialog.Header withBorder>{title}</Dialog.Header>
      <Dialog.Body>
        <p className={styles.modalText}>{body}</p>
        <div className={styles.row}>
          <strong>Transaction ID:</strong>
          <div className={styles.hash}>
            {utils.shortenAddress(tx?.hash)} <CopyButton text={tx?.hash} />
          </div>
        </div>

        <div className={styles.row}>
          <strong>Check Status:</strong>
          <a
            href={generateBlockExplorerUrl(tx?.hash)}
            target="_blank"
            rel="noreferrer"
          >
            Polygonscan
          </a>
        </div>

        <Button className={styles.CTA} onClick={onClose} isLoading={isLoading}>
          {buttonText}
        </Button>
      </Dialog.Body>
    </Dialog.Container>
  );
}
