import clsx from 'clsx';
import React, { useState } from 'react';

import { useLinkedAddress, useWalletSummary } from '@/api/api-hooks';
import infoIcon from '@/assets/images/button-information.svg';
import metamaskIcon from '@/assets/images/metamask.svg';
import { BiTrigger } from '@/bi/bi-types';
import { commonBiEvents } from '@/bi/events/common-bi-events';
import { metaMaskBiEvents } from '@/bi/events/metamask-events';
import utils from '@/common/utils';
import { Status, useWeb3Context } from '@/context/web3-context';

import Button from '../button/button';

import MetamaskButtonHeader from './metamask-button-header';
import * as styles from './metamask-button.module.css';
import SignatureModal from './signature-modal';

const statusText: Record<Status, string> = {
  loading: 'LoadinSign your Metamask walletg...',
  noUser: 'Please Log In to Continue',
  noMetaMask: 'MetaMask',
  notConnected: 'Connect Metamask',
  connecting: 'Connecting',
  connected: 'Connected',
  unsigned: 'Sign',
  signing: 'Approve to Continue',
  signed: 'Signed',
  differentAccount: 'Please Connect Using ',
  rejected: 'Approve to Continue',
};

type MetamaskButtonProps = {
  biTrigger: BiTrigger;
  showInfoIcon?: boolean;
  nftIds?: string[];
  nftCount?: number;
  hideHeader?: boolean;
  noScale?: boolean;
  className?: string;
};

export default function MetamaskButton({
  biTrigger,
  showInfoIcon = true,
  nftIds,
  nftCount,
  hideHeader,
  noScale = false,
  className = '',
}: MetamaskButtonProps) {
  const { connect, requestSignature, status } = useWeb3Context();
  const { linkedAddress } = useLinkedAddress();
  const { jrWalletAddress, jrWalletBalance } = useWalletSummary();
  const [showSignModal, setShowSignModal] = useState(false);

  const handleConnectClick = async () => {
    // debugger;
    commonBiEvents.userClicksOnSomeCta(
      biTrigger,
      status === 'notConnected' ? 'connect_wallet' : 'sigin_wallet',
      '',
      'metamask',
      '',
      nftIds,
      '',
      nftCount,
      0,
    );

    if (status === 'unsigned') {
      setShowSignModal(true);
      return;
    }

    try {
      await connect();

      metaMaskBiEvents.connectsWallet(
        biTrigger,
        jrWalletAddress,
        jrWalletBalance,
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleSignClick = () => {
    requestSignature().then(() => setShowSignModal(false));
  };

  return (
    <>
      <div className={styles.wrapper}>
        {!hideHeader && (
          <MetamaskButtonHeader status={status} biTrigger={biTrigger} />
        )}
        <div
          className={clsx(
            styles.container,
            showInfoIcon && styles.withInfoIcon,
          )}
        >
          <Button
            variant="white"
            isLoading={status === 'connecting'}
            disabled={status === 'signed'}
            onClick={handleConnectClick}
            className={className}
          >
            <div className={styles.buttonContainer}>
              <img src={metamaskIcon} alt="" width={35} height={33} />
              {status === 'differentAccount'
                ? statusText[status] + utils.shortenAddress(linkedAddress)
                : statusText[status]}
              {!status ? 'Metamask' : ''}
            </div>
          </Button>

          {showInfoIcon && (
            <div className={styles.infoWrapper}>
              <img src={infoIcon} width={17} alt="information" />
              <div className={styles.tooltip}>
                Only one Metamask wallet address can be connected to your
                Superchamps account. Please connect your primary wallet with
                Superchamps NFTs.
              </div>
            </div>
          )}
        </div>
      </div>

      <SignatureModal
        isOpen={showSignModal}
        isLoading={status === 'signing'}
        onClose={() => setShowSignModal(false)}
        onSignClick={handleSignClick}
      />
    </>
  );
}
