import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';

import copyIcon from '@/assets/images/icons/icon-copy.svg';

import * as styles from './copy-button.module.css';

export default function CopyButton({
  text,
  buttonText,
  className = '',
  onBtnClickCallback,
}: {
  text?: string;
  className?: string;
  buttonText?: string;
  onBtnClickCallback?: () => void;
}) {
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);

  const handleCopyClick = async () => {
    try {
      if (!text) return;

      try {
        await navigator.clipboard.writeText(text);
        setShowCopiedTooltip(true);
        setTimeout(() => setShowCopiedTooltip(false), 2000);
      } catch (error) {
        // https://stackoverflow.com/questions/61243646/clipboard-api-call-throws-notallowederror-without-invoking-onpermissionrequest
        console.error(error);
        toast.error(
          'Your browser does not support copying to clipboard. Please open this page in a different browser.',
        );
      }
    } finally {
      try {
        if (onBtnClickCallback) {
          onBtnClickCallback();
        }
      } catch (e) {
        //DO NOTHING
      }
    }
  };

  return (
    <>
      <button
        className={className || styles.button}
        type="button"
        data-tooltip-id="copied"
        onClick={handleCopyClick}
      >
        {buttonText || (
          <img src={copyIcon} width={24} height={24} alt="copy address" />
        )}
      </button>
      <Tooltip
        id="copied"
        content="Copied!"
        isOpen={showCopiedTooltip}
        style={{ fontSize: '14px' }}
      />
    </>
  );
}
