import { ContractTransactionResponse } from 'ethers';
import React, { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';

import { isMobile } from '@/common/utils';
import Spinner from '@/components/spinner/spinner';

import { ROUNDS } from '../config';
import { shouldShowCongratsModal } from '../helpers';
import useTickets from '../use-tickets';

import CongratsModal from './congrats-modal';
import Ticket from './ticket';
import * as styles from './ticket-list.module.css';
import TransactionModal from './transaction-modal';

import '../react-paginate.css';


export default function TicketList({ id }: { id: string }) {
  const [page, setPage] = useState(0);
  const { pageCount, data, biggestClaimable, isLoading, refreshTickets } =
    useTickets({ page });
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
  const [isCongratsModalOpen, setIsCongratsModalOpen] = useState(false);
  const [tx, setTx] = useState<ContractTransactionResponse | null>(null);
  const headingRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (tx) {
      setIsCongratsModalOpen(false);
      setIsTransactionModalOpen(true);
    }
  }, [tx]);

  useEffect(() => {
    if (biggestClaimable && Date.now() > ROUNDS[ROUNDS.length - 1].dateMs) {
      setIsCongratsModalOpen(shouldShowCongratsModal());
    }
  }, [biggestClaimable]);

  const handlePageClick = ({ selected }: { selected: number }) => {
    setPage(selected);
    headingRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  if (!isLoading && !data.length) {
    return null;
  }

  return (
    <>
      <section>
        <h2 className={styles.sectionHeading} ref={headingRef}>
          Raffle Tickets
        </h2>
        <div className={styles.ticketList} id={id}>
          {isLoading ? (
            <div className={styles.spinnerWrapper}>
              <Spinner />
            </div>
          ) : (
            data.map((ticket) => (
              <Ticket key={ticket.id} ticket={ticket} setTx={setTx} />
            ))
          )}
        </div>
        {pageCount < 2 ? null : (
          <ReactPaginate
            containerClassName="react-paginate"
            breakLabel=".."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={isMobile() ? 1 : 3}
            marginPagesDisplayed={isMobile() ? 1 : 3}
            prevRel={null}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
          />
        )}
      </section>
      <TransactionModal
        isOpen={isTransactionModalOpen}
        onClose={() => {
          setIsTransactionModalOpen(false);
          refreshTickets();
        }}
        tx={tx}
      />
      <CongratsModal
        isOpen={isCongratsModalOpen}
        onClose={() => setIsCongratsModalOpen(false)}
        ticket={biggestClaimable}
        setTx={setTx}
      />
      <hr className={styles.hr} />
    </>
  );
}
