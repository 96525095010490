import { Link } from 'gatsby';
import React from 'react';

// import iconDiamond from '@/assets/images/icons/icon-diamond.svg';
import iconRacketRampage from '@/assets/images/rr-logo-128x128.png';
// import iconRacketRampage from '@/assets/images/icons/icon-racket-ramapge.svg';
// import iconMarketplace from '@/assets/images/icons/icon-marketplace.svg';
// import iconTicket from '@/assets/images/icons/icon-ticket.svg';
import superChampsLogo from '@/assets/images/superchamps-logo.png';
// import CONSTANTS from '@/common/constants';
import useLocation from '@/hooks/use-location';

import * as styles from './navbar.module.css';

export type TNavItem = {
  name: string;
  icon: any;
  routes: string[];
  startsWith: string;
  disabled?: boolean;
};

const navItems: TNavItem[] = [
  {
    name: 'Racket Rampage',
    icon: iconRacketRampage,
    routes: ['/marketplace'],
    startsWith: '/marketplace',
  },
];

const hideNavBarFor = ['/wallet'];

interface NavItemProps {
  name: string;
  icon: string;
  route: string;
  active: boolean;
}

const NavItem: React.FC<NavItemProps> = ({ name, icon, route, active }) => (
  <li>
    <Link className={`${active ? 'active' : ''}`} to={route}>
      <img className="nav-links-icon" src={icon} alt={name} />
      <span>{name}</span>
    </Link>
  </li>
);

const Navbar: React.FC = () => {
  const { pathname } = useLocation();
  const strippedPathname = pathname.replace(/\/$/, '');

  const hideNavBar = hideNavBarFor.includes(pathname);

  return (
    <nav className={`${styles.nav} ${hideNavBar ? 'no-border' : ''}`}>
      <div className={styles.wrapper}>
        <Link className="superchamps-logo" to="/">
          <img src={superChampsLogo} alt="Logo" width={146} />
        </Link>
        {!hideNavBar && (
          <ul className="nav-links">
            {navItems.map((item) => {
              if (item.disabled) return null;

              return (
                <NavItem
                  key={item.name}
                  name={item.name}
                  icon={item.icon}
                  route={item.routes[0]}
                  active={strippedPathname.startsWith(item.startsWith)}
                />
              );
            })}
          </ul>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
