import { useCallback, useEffect, useRef, useState } from 'react';

import { useRaffleTicketIds } from '@/api/api-hooks';
import { isMobile } from '@/common/utils';

import { removeWinnerStylesFromSVG } from './transform-ticket-svg';
import { Ticket } from './types';
import { getTicketURI } from './web3-raffle';


const ITEMS_PER_PAGE = isMobile() ? 5 : 12;
const CACHE = new Map<string, Ticket>();

const hasBiggestClaimable = (ticket: Ticket | undefined) => {
  if (ticket && ticket.winner && !ticket.claimed) {
    const clone = Object.assign({}, ticket);
    clone.image = removeWinnerStylesFromSVG(clone.image);

    return clone;
  }

  return undefined;
};

export default function useTickets({ page }: { page: number }) {
  const { tokenIds } = useRaffleTicketIds();
  const [data, setData] = useState<Ticket[]>([]);
  const [biggestClaimable, setBiggestClaimable] = useState<
    Ticket | undefined
  >();
  const [isLoading, setIsLoading] = useState(true);
  const pageRef = useRef<number>();

  const pageCount = Math.ceil((tokenIds?.length ?? 0) / ITEMS_PER_PAGE);

  const loadTickets = useCallback(
    async (cleanCache?: boolean) => {
      if (!tokenIds) return;

      if (!tokenIds.length) {
        setIsLoading(false);
        return;
      }

      if (cleanCache) {
        CACHE.clear();
      }

      setIsLoading(true);

      pageRef.current = page;
      const offset = (page * ITEMS_PER_PAGE) % tokenIds.length;
      const endOffset = offset + ITEMS_PER_PAGE;

      Promise.all(
        tokenIds
          .slice(offset, endOffset)
          .map((tokenId) => CACHE.get(tokenId) || getTicketURI(tokenId)),
      )
        .then((tickets) => {
          const filteredTickets = tickets.filter(Boolean) as Ticket[];

          filteredTickets.forEach((ticket) => {
            CACHE.set(ticket.id, ticket);
          });

          if (page === 0 && !biggestClaimable) {
            setBiggestClaimable(hasBiggestClaimable(filteredTickets[0]));
          }

          // page might have changed while we were fetching
          if (page !== pageRef.current) return;

          setData(filteredTickets);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    },
    [tokenIds, page, biggestClaimable],
  );

  useEffect(() => {
    loadTickets();
  }, [loadTickets]);

  return {
    pageCount,
    data,
    biggestClaimable,
    isLoading,
    refreshTickets: () => loadTickets(true),
  };
}
