// extracted by mini-css-extract-plugin
export var activeDrawContainer = "raffle-page-module--activeDrawContainer--9c4e6";
export var activePhase = "raffle-page-module--activePhase--544d1";
export var box = "raffle-page-module--box--5c0be";
export var boxContainer = "raffle-page-module--boxContainer--54dd9";
export var buttonContainer = "raffle-page-module--buttonContainer--3de70";
export var container = "raffle-page-module--container--0d581";
export var currentPhase = "raffle-page-module--currentPhase--b78b0";
export var date = "raffle-page-module--date--096ee";
export var desc = "raffle-page-module--desc--4c6d5";
export var downButton = "raffle-page-module--downButton--20c12";
export var line = "raffle-page-module--line--eed0b";
export var mascot = "raffle-page-module--mascot--22f58";
export var mascotContainer = "raffle-page-module--mascotContainer--1c53f";
export var mintDateContainer = "raffle-page-module--mintDateContainer--2437a";
export var mintDateSection = "raffle-page-module--mintDateSection--02938";
export var passiveAirdropContainer = "raffle-page-module--passiveAirdropContainer--aed6d";
export var premint = "raffle-page-module--premint--a4d2d";
export var scheduleContainer = "raffle-page-module--scheduleContainer--d9e97";
export var sectionHeading = "raffle-page-module--sectionHeading--787c4";
export var separator = "raffle-page-module--separator--ae6de";
export var time = "raffle-page-module--time--69fc8";